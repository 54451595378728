import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'

import * as styles from './Recipe.module.scss'
import { Props } from './types'

const Recipe = ({ blok }: Props): JSX.Element => {
  const {
    title,
    image,
    ingredients,
    preparation,
    description,
    ingredients_items,
    how_to_do,
    recipe_for_total_persons,
  } = blok
  const [activeTab, setActiveTab] = useState('ingredients')

  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={[1, null, null, 1 / 2]}
          paddingX={[4, null, null, 6]}
          paddingTop={[0, null, null, 30]}
          paddingBottom={[7, null, null, 30]}
        >
          <Flex
            flexDirection={['column', null, null, 'row']}
            width={[1, null, null, 4 / 6]}
          >
            <Flex
              flexDirection={['row', null, null, 'column']}
              width={[1, null, null, 2 / 6]}
              alignItems="flex-end"
            >
              <Box
                marginBottom={[0, null, null, 4]}
                width={[1 / 2, null, null, 1]}
              >
                <Box
                  className={classnames(
                    styles.tab,
                    activeTab === 'ingredients' ? styles.active : '',
                    'use-editor'
                  )}
                  as="button"
                  onClick={() => setActiveTab('ingredients')}
                >
                  {`Ingredienser`}
                </Box>
              </Box>
              <Box width={[1 / 2, null, null, 1]}>
                <Box
                  className={classnames(
                    styles.tab,
                    activeTab === 'preparation' ? styles.active : '',
                    'use-editor'
                  )}
                  as="button"
                  onClick={() => setActiveTab('preparation')}
                >
                  {`Gör så här`}
                </Box>
              </Box>
            </Flex>
            <Box
              width={[1, null, null, 4 / 6]}
              marginTop={[7, null, null, 0]}
              paddingLeft={[0, null, null, 10]}
              className={styles.content}
            >
              {ingredients && activeTab === 'ingredients' && (
                <Box className={styles.text} paddingLeft={[0, null, null, 6]}>
                  {recipe_for_total_persons && recipe_for_total_persons > 0 && (
                    <span
                      className={styles.recipeFor}
                    >{`Recept för ${recipe_for_total_persons}`}</span>
                  )}
                  {ingredients_items &&
                    ingredients_items.map((blok: any) => (
                      <div className={styles.list}>
                        {blok.label && (
                          <span className={styles.label}>{blok.label}</span>
                        )}
                        <ul>
                          {blok.ingredients &&
                            blok.ingredients.map((list: any) => (
                              <li>{list.text}</li>
                            ))}
                        </ul>
                      </div>
                    ))}
                </Box>
              )}
              {preparation && activeTab === 'preparation' && (
                <Box className={styles.text} paddingLeft={[0, null, null, 6]}>
                  {how_to_do &&
                    how_to_do.map((blok: any) => (
                      <div className={styles.list}>
                        {blok.label && (
                          <span className={styles.label}>{blok.label}</span>
                        )}
                        <ol>
                          {blok.rows &&
                            blok.rows.map((list: any) => <li>{list.text}</li>)}
                        </ol>
                      </div>
                    ))}
                </Box>
              )}
            </Box>
          </Flex>
        </Flex>
        <Box width={[1, null, null, 1 / 2]}>
          <Box className={styles.imageWrapper} width={1}>
            {image?.filename && <Image src={image.filename} alt={title} />}
          </Box>
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default Recipe
